import React, { useState, useEffect } from 'react';
import { getUser } from '@data/redux/usersSlice';
import { useSelector } from 'react-redux';
import Popup from "@components/Popup";
import TextInput from "@components/Inputs/TextInput";
import { getCollection } from '@data/firebase/firestore/getData';
import { createDocument } from '@data/firebase/firestore/saveData';
import { deleteDocument } from '@data/firebase/firestore/deleteData';
import { sendPasswordResetEmail } from '@data/firebase/auth'; // Import the reset password function
import DeletePopup from "@components/DeletePopup";
import { formatDateTime, handleSortDrill } from "@data/helpers";
import Loading from "@components/Loading";

const AdminUsers = () => {
  const user_store = useSelector(getUser);
  const [users, setUsers] = useState([]);
  const [popupEditUser, setPopupEditUser] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [promo, setPromo] = useState('');
  const [userId, setUserId] = useState(null);
  const [editUserError, setEditUserError] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [popupDeleteUser, setPopupDeleteUser] = useState(false);
  const [searchUser, setSearchUser] = useState('');
  const [sortConfigUser, setSortConfigUser] = useState({ key: 'lastLogin', direction: 'desc' });
  const [popupMessage, setPopupMessage] = useState(''); // State for popup messages
  const [isPopupActive, setIsPopupActive] = useState(false); // State to control popup visibility

  const getUsersData = () => {
    getCollection('users', (response) => {
      setUsers(response);
    });
  };

  useEffect(() => {
    getUsersData();
  }, []);

  const saveUser = () => {
    setEditUserError(false);
    setUserLoading('Saving User...');
    if (firstName === '' || lastName === '' || email === '') {
      setUserLoading(false);
      setEditUserError('Please fill in all fields');
    } else {
      const userData = {
        firstName,
        lastName,
        email,
        promo
      };

      if (userId) {
        // Update existing user
        createDocument('users', userData, () => {
          setUserLoading('User Updated Successfully');
          resetUserForm();
          getUsersData();
          setTimeout(() => {
            setPopupEditUser(false);
            setUserLoading(false);
          }, 2000);
        }, userId, true);
      }
    }
  };

  const resetUserForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPromo('');
    setUserId(null);
  };

  const deleteUser = () => {
    deleteDocument('users', popupDeleteUser.id, () => {
      getUsersData();
      setPopupDeleteUser(false);
    });
  };

  const handleEditUser = (user) => {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user.email);
    setPromo(user.promo);
    setUserId(user.id);
    setPopupEditUser(true);
  };

  const handleSort = (key) => {
    handleSortDrill(key, sortConfigUser, setSortConfigUser);
  };

  // Function to get the sorting class
  const getSortClass = (key) => {
    return sortConfigUser.key === key ? `sort ${sortConfigUser.direction}` : '';
  };

  const handleResetPassword = (email) => {
    sendPasswordResetEmail(email)
      .then(() => {
        setPopupMessage('Password reset email sent successfully.');
        setIsPopupActive(true);
      })
      .catch((error) => {
        console.error('Error sending password reset email:', error);
        setPopupMessage('Failed to send password reset email. Please try again.');
        setIsPopupActive(true);
      });
  };

  // Filter and sort users based on search input and sorting configuration
  const sortedAndFilteredUsers = users
    .filter((user) =>
      user.firstName.toLowerCase().includes(searchUser.toLowerCase()) ||
      user.lastName.toLowerCase().includes(searchUser.toLowerCase()) ||
      user.email.toLowerCase().includes(searchUser.toLowerCase())
    )
    .sort((a, b) => {
      if (sortConfigUser.key) {
        if (a[sortConfigUser.key] < b[sortConfigUser.key]) {
          return sortConfigUser.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfigUser.key] > b[sortConfigUser.key]) {
          return sortConfigUser.direction === 'asc' ? 1 : -1;
        }
      }
      return 0;
    });

  return (
    <div className="users-content">
      <Popup
        title="Edit User"
        active={popupEditUser}
        onChange={saveUser}
        closePopup={() => { setPopupEditUser(false); resetUserForm(); }}
      >
        {userLoading && <Loading loading={userLoading} />}
        <form>
          <TextInput value={firstName} label="First Name" onChange={(e) => setFirstName(e)} placeholder="First Name" />
          <TextInput value={lastName} label="Last Name" onChange={(e) => setLastName(e)} placeholder="Last Name" />
          <TextInput value={email} label="Email" onChange={(e) => setEmail(e)} placeholder="Email" />
          <TextInput value={promo} label="Promo" onChange={(e) => setPromo(e)} placeholder="Promo" />
          {editUserError && <span className="error-msg">{editUserError}</span>}
        </form>
      </Popup>
      <DeletePopup
        subject={`${popupDeleteUser.firstName} ${popupDeleteUser.lastName}`}
        title="Delete User"
        active={popupDeleteUser.id}
        onChange={deleteUser}
        closePopup={() => { setPopupDeleteUser(false); }}
      />
      <div className="search-add">
        <input type="text" placeholder="Search by name or email" value={searchUser} onChange={(event) => setSearchUser(event.target.value)} />
      </div>
      <table>
        <thead>
          <tr>
            <th onClick={() => handleSort('lastLogin')} className={getSortClass('lastLogin')}>Last Login</th>
            <th onClick={() => handleSort('firstName')} className={getSortClass('firstName')}>First Name</th>
            <th onClick={() => handleSort('lastName')} className={getSortClass('lastName')}>Last Name</th>
            <th onClick={() => handleSort('email')} className={getSortClass('email')}>E-mail</th>
            <th>Subscription</th>
            <th>Promo</th>
            <th>Question 1</th>
            <th>Question 2</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortedAndFilteredUsers.map((val, key) => (
            <tr key={key}>
              <td>{formatDateTime(val.lastLogin)}</td>
              <td>{val.firstName}</td>
              <td>{val.lastName}</td>
              <td>{val.email}</td>
              <td>{val.subscription}</td>
              <td>{val.promo}</td>
              <td>{val.answer_1}</td>
              <td>{val.answer_2?.join(", ")}</td>
              <td className="actions">
                <div onClick={() => handleEditUser(val)} className="btn-edit">Edit</div>
                <div onClick={() => setPopupDeleteUser({ id: val.id, firstName: val.firstName, lastName: val.lastName })} className="btn-delete">Delete</div>
                <div onClick={() => handleResetPassword(val.email)} className="btn-reset">Reset Password</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Popup
        savePopup={false}
        cancel={false}
        title="Reset Password"
        active={isPopupActive}
        onChange={() => setIsPopupActive(false)}
        closePopup={() => setIsPopupActive(false)}
      >
        <p>{popupMessage}</p>
      </Popup>
    </div>
  );
};

export default AdminUsers;
