import React, { useState, useEffect } from 'react';
import { getCollection, getCollectionQuery } from '@data/firebase/firestore/getData';
import { createDocument } from '@data/firebase/firestore/saveData'; // Import save function
import { getUser } from '@data/redux/usersSlice';
import { useSelector } from 'react-redux';
import { formatTimeMinutes, formatDateTime, sortedAndFilteredDrills, handleSortDrill } from '@data/helpers';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from React Router
import Popup from "@components/Popup";
import Metronome from "@pages/Admin/Home/Practice";

const History = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'datetime', direction: 'desc' });
  const [drillHistory, setDrillHistory] = useState([]);
  const [isEditing, setIsEditing] = useState(null); // Track the row being edited
  const [editNote, setEditNote] = useState(''); // Track the edited note
  const user_store = useSelector(getUser);
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  const [popupMetronome, setPopupMetronome] = useState(false);
  const [popupBpm, setPopupBpm] = useState(100);
  const [popupName, setPopupName] = useState('');

  // Function to determine the sort class for table headers
  const getSortClass = (key) => {
    return sortConfig.key === key ? `sort ${sortConfig.direction}` : '';
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (key) => {
    handleSortDrill(key, sortConfig, setSortConfig);
  };

  useEffect(() => {
    getCollectionQuery('users/' + user_store.email + '/logs', (response) => {
      setDrillHistory(response);
    }, null, { field: 'datetime', value: 'desc' });
  }, [user_store.email]);

  // Use sortedAndFilteredDrills helper function
  const sortedAndFilteredData = sortedAndFilteredDrills(drillHistory, searchTerm, sortConfig);

  // const handlePracticeWithSettings = (drillName, bpm) => {
  //   navigate('/practice', { state: { drillName, bpm } }); // Navigate to the Practice page with drillName and bpm
  // };

  // Start editing a note
  const handleEditNote = (key, note) => {
    setIsEditing(key);
    setEditNote(note || '');
  };

  // Save the edited note
  const handleSaveNote = (logId) => {
    const updatedLog = {
      ...drillHistory.find(log => log.id === logId), // Find the log being edited
      note: editNote, // Update the note
    };

    // Save the updated log in Firestore
    createDocument(`users/${user_store.email}/logs`, updatedLog, () => {
      // Update the local state after saving
      setDrillHistory((prevLogs) =>
        prevLogs.map((log) =>
          log.id === logId ? { ...log, note: editNote } : log
        )
      );
      setIsEditing(null); // Stop editing mode
    }, logId, true);
  };

  const handlePracticeWithSettings = (drillName, bpm) => {
    setPopupMetronome(true);
    setPopupBpm(bpm);
    setPopupName(drillName);
    //navigate('/practice', { state: { drillName, bpm } });
  };

  return (
    <div id="history">
      <Popup
        title="Metronome" active={popupMetronome} cancel={false} savePopup={false}
        closePopup={() => { setPopupMetronome(false); }}
      >
        <Metronome startname={popupName} startbmp={popupBpm} />
      </Popup>
      <div className="box-wrapper">
        <div className="box">
          <div className="box-header">
            <h3 className="title">Practice History</h3>            
          </div>
          <div className="box-search">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          <div className="box-content">
            <table>
              <thead>
                <tr>
                  <th
                    className={`sortable hide-mobile ${getSortClass('datetime')}`}
                    onClick={() => handleSort('datetime')}
                  >
                    Date
                  </th>
                  <th
                    className={`sortable ${getSortClass('drill')}`}
                    onClick={() => handleSort('drill')}
                  >
                    Drill name
                  </th>
                  <th
                    className={`sortable ${getSortClass('seconds')}`}
                    onClick={() => handleSort('seconds')}
                  >
                    Total Min
                  </th>
                  <th
                    className={`sortable ${getSortClass('bpm')}`}
                    onClick={() => handleSort('bpm')}
                  >
                    BPM
                  </th>
                  <th
                    className={`sortable ${getSortClass('note')}`}
                    onClick={() => handleSort('note')}
                  >
                    Note
                  </th>
                  <th>Signature</th>
                  <th></th> {/* Action buttons column */}
                </tr>
              </thead>
              <tbody>
                {sortedAndFilteredData.map((val, key) => (
                  <tr key={key}>
                    <td className="hide-mobile">{formatDateTime(val.datetime)}</td>
                    <td>{val.drill}</td>
                    <td>{formatTimeMinutes(val.seconds)}</td>
                    <td>{val.bpm}</td>
                    <td>
                      {isEditing === key ? (
                        <input
                          type="text"
                          value={editNote}
                          onChange={(e) => setEditNote(e.target.value)}
                        />
                      ) : (
                        <span title={val.note} className="note">{val.note}</span>
                      )}
                    </td>
                    <td>{val.signature}</td>
                    <td>
                      {isEditing === key ? (
                        <>
                          <button className="btn-action btn-save" onClick={() => handleSaveNote(val.id)}>Save</button>
                          <button className="btn-action btn-cancel" onClick={() => setIsEditing(null)}>Cancel</button>
                        </>
                      ) : (
                        <>
                          <button onClick={() => handleEditNote(key, val.note)} className="btn-action">Edit</button>
                          <button
                            onClick={() => handlePracticeWithSettings(val.drill, val.bpm)}
                            className="btn-action"
                          >
                            Load Drill
                          </button>
                          
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
