import React, { useState } from 'react';
import BoxGraph from './Components/BoxGraph';
import BoxRecent from './Components/BoxRecent';
import BoxDrill from './Components/BoxDrill';
import BoxCalenderActivity from './Components/BoxCalenderActivity';
import { useNavigate } from 'react-router-dom';
import Popup from "@components/Popup";
import Metronome from "@pages/Admin/Home/Practice";

const Goals = () => {
  const [popupMetronome, setPopupMetronome] = useState(false);
  const [popupBpm, setPopupBpm] = useState(100);
  const [popupName, setPopupName] = useState('');

  const navigate = useNavigate();
  const handlePracticeWithSettings = (drillName, bpm) => {
    setPopupMetronome(true);
    setPopupBpm(bpm);
    setPopupName(drillName);
    //navigate('/practice', { state: { drillName, bpm } });
  };

  return (
    <div id="goals">
      <Popup
        title="Metronome" active={popupMetronome} cancel={false} savePopup={false}
        closePopup={() => { setPopupMetronome(false); }}
      >
        <Metronome startname={popupName} startbmp={popupBpm} />
      </Popup>
      <div className="box-wrapper dashboard-first">
        <div className="box-insection">
          <BoxGraph />
          <BoxRecent handlePracticeWithSettings={handlePracticeWithSettings} />
        </div>
        <BoxDrill handlePracticeWithSettings={handlePracticeWithSettings} />
      </div>
      <BoxCalenderActivity handlePracticeWithSettings={handlePracticeWithSettings} />
    </div>
  );
};

export default Goals;
