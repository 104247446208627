import React, { useState, useEffect } from 'react';
import "./ContactForm.scss";

const ContactForm = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [completed, setCompleted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(false);
    if (name === '' || email === '' || message === '') {
      setError('Please fill in all fields');
    } else {
      console.log({ name, email, message });
      setCompleted(true);
    }
  }

  return (
     <div className="contact-form">
      <div className="content">
        <h3>Contact</h3>
        {
          completed ? (
            <span className="description">
              Thank you for your message, we will contact you as soon as possible.<br /><br />Have an urgent question?<br />Feel free to call us at +32 485 45 56 05.
            </span>
          ) : (
            <>
              <span className="description">
                Have a question?<br />You can always contact us filling in this form.
              </span>
              <form onSubmit={handleSubmit}>
                <input 
                  type="text" 
                  onChange={(e) => setName(e.target.value)} 
                  value={name} 
                  placeholder="Your name" 
                />
                <input 
                  type="text" 
                  onChange={(e) => setEmail(e.target.value)} 
                  value={email} 
                  placeholder="Email or phone number" 
                />
                <input 
                  type="text" 
                  onChange={(e) => setMessage(e.target.value)} 
                  value={message} 
                  placeholder="Message" 
                />
                {error && <span className="error">{error}</span>}
                <input className="btn-main" type="submit" value="Send" />
              </form>
            </>
          )
        }
      </div>
    </div>
  );
};

export default ContactForm;
