import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { uploadFile } from "@data/firebase/storage";

// Use forwardRef to expose internal methods to parent components
const UploadFile = forwardRef(({ filepath, onUploadComplete }, ref) => {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null); // Add ref for the file input

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      uploadFile(
        file,
        filepath,
        (progress) => {
          setProgress(progress);
        },
        (fileInfo) => {
          setIsUploading(false);
          onUploadComplete(fileInfo);
        },
        (error) => {
          setIsUploading(false);
          console.error("Upload failed", error);
        }
      );
    }
  };

  // Use useImperativeHandle to expose a custom method to reset the file input
  useImperativeHandle(ref, () => ({
    resetFileInput: () => {
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clear the file input value
      }
    }
  }));

  return (
    <div>
      <input ref={fileInputRef} type="file" onChange={handleFileChange} />
      {isUploading && <p>Upload progress: {progress.toFixed(2)}%</p>}
    </div>
  );
});

export default UploadFile;
