import React, { useState } from 'react';
import { getUser, setUser, setUserGoal } from '@data/redux/usersSlice';
import { useSelector, useDispatch } from 'react-redux';
import { updateProfile, sendPasswordResetEmail } from '@data/firebase/auth'; // Assuming you have these utilities in your firebase setup
import TextInput from '@components/Inputs/TextInput';
import UploadFile from "@components/UploadFile";
import Loading from "@components/Loading";
import Popup from "@components/Popup"; // Importing the Popup component
import { createDocument } from '@data/firebase/firestore/saveData'; // Importing createDocument for updates

const AdminProfile = () => {
  const user_store = useSelector(getUser);
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState(user_store.firstName || '');
  const [lastName, setLastName] = useState(user_store.lastName || '');
  const [email, setEmail] = useState(user_store.email || '');
  const [avatar, setAvatar] = useState(user_store.avatar || '');
  const [goal, setGoal] = useState((user_store.goal/60) || 0);
  const [profileLoading, setProfileLoading] = useState(false);
  const [updateError, setUpdateError] = useState('');
  const [popupMessage, setPopupMessage] = useState(''); // State to hold popup message
  const [isPopupActive, setIsPopupActive] = useState(false); // State to control popup visibility

  const handleSaveProfile = () => {
    setProfileLoading(true);
    setUpdateError('');
    
    const updatedProfile = {
      firstName,
      lastName,
      avatar,
      goal: parseInt(goal, 10)*60,
      email
    };
    console.log(updatedProfile, email);

    createDocument('users', updatedProfile, (response) => {
      console.log(response);
      if (response.type === 'success') {
        dispatch(setUser(updatedProfile));
        dispatch(setUserGoal(updatedProfile.goal));
        setProfileLoading(false);
        setPopupMessage('Profile updated successfully.');
        setIsPopupActive(true);
      } else {
        setUpdateError('Failed to update profile. Please try again.');
        setProfileLoading(false);
      }
    }, email, true);
  };

  const handleUploadComplete = (fileInfo) => {
    setAvatar(fileInfo.url);
  };

  const handleResetPassword = () => {
    sendPasswordResetEmail(email)
      .then(() => {
        setPopupMessage('Password reset email sent successfully.');
        setIsPopupActive(true);
      })
      .catch((error) => {
        console.error('Error sending password reset email:', error);
        setPopupMessage('Failed to send password reset email. Please try again.');
        setIsPopupActive(true);
      });
  };

  return (
    <div className="admin-profile">
      {profileLoading && <Loading loading={profileLoading} />}
      <form onSubmit={(e) => { e.preventDefault(); handleSaveProfile(); }}>
        <TextInput value={firstName} label="First Name" onChange={(e) => setFirstName(e)} placeholder="First Name" />
        <TextInput value={lastName} label="Last Name" onChange={(e) => setLastName(e)} placeholder="Last Name" />
        <TextInput className="non-editable" value={email} label="Email" onChange={() => {}} placeholder="Email" disabled={true} />
        <div style={{ marginBottom: 20, float: 'left' }}>
          <button type="button" onClick={handleResetPassword} className="btn-reset">Reset Password</button>
        </div>
        <div className="avatar-upload">
          <label>Profile Picture</label>
          {avatar && (
            <div className="image-preview">
              <img src={avatar} alt="Avatar Preview" style={{ width: '100px', height: '100px', marginBottom: '10px' }} />
            </div>
          )}
          <UploadFile filepath={`/avatars/${user_store.id}`} onUploadComplete={handleUploadComplete} />
        </div>
        <TextInput value={goal} label="Goal" type="number" onChange={(e) => setGoal(e)} placeholder="Daily practice goals (min)" />
        {updateError && <span className="error-msg">{updateError}</span>}
        <div className="profile-actions">
          <button type="submit" className="btn-main">Save Profile</button>
          
        </div>
      </form>
      <Popup
        title="Notification"
        savePopup={false}
        cancel={false}
        active={isPopupActive}
        onChange={() => setIsPopupActive(false)}
        closePopup={() => setIsPopupActive(false)}
      >
        <p>{popupMessage}</p>
      </Popup>
    </div>
  );
};

export default AdminProfile;
