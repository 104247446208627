import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCollectionQuery } from '@data/firebase/firestore/getData';
import { getUser } from '@data/redux/usersSlice';
import { groupByKey } from '@data/helpers';

const BoxRecent = ({ handlePracticeWithSettings }) => {
  const [drillHistory, setDrillHistory] = useState([]);
  const user_store = useSelector(getUser);

  useEffect(() => {
    getCollectionQuery('users/' + user_store.email + '/logs', (response) => {
      console.log(response);
      setDrillHistory(response);
    }, null, { field: 'datetime', order: 'desc' });
  }, [user_store.email]);

  return (
    <div className="box recent">
      <div className="box-header">
        <span className="title">Recent Drills</span>
      </div>
      <div className="box-content">
        <ul className="drills-recent">
          {
            groupByKey(drillHistory, 'drill').slice(0, 6).map((value, key) => (
              <li key={key} onClick={() => handlePracticeWithSettings(value[0].drill, value[0].bpm)}>
                <i>{value.length}</i>
                <span>{value[0].drill}</span>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
};

export default BoxRecent;
